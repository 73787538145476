import { useCallback, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { runDeviceRestockingCheck } from '@hero/redux-data/backoffice/deviceRestockingCheck/actionCreators';
import trimDeep from '@hero/hero-utils/trimDeep';
import { formatPhoneNumber } from '@hero/hero-utils/phone';
import useCreateRmaDraft from '../pages/Rma/api/useCreateRmaDraft';
import useSetRmaPickup from '../pages/Rma/api/useSetRmaPickup';
export const getRefundStatus = (status) => {
    if (status === 'Processed') {
        return 1;
    }
    if (status === 'N/A') {
        return 3;
    }
    return 2;
};
const useRmaRequest = ({ isOrganizationMember = false, cancellationDetails, orgCancellationDetails, cancellation, deviceSerial, rmaRequest, disableCreateRma = false }) => {
    const dispatch = useDispatch();
    const previouslyCreatedRma = isOrganizationMember
        ? orgCancellationDetails?.rma
        : cancellationDetails?.rma;
    const [isPickUpSet, setPickUpSet] = useState(false);
    const { data: rmaPickupRequestResponse, mutateAsync: setRmaRequestPickUp, error: setRmaPickupError, isPending: isSetRmaPickupLoading } = useSetRmaPickup();
    const { data: rmaDraftResponse, mutate: createDraftRmaRequest, error: createRmaDraftError, isError: isCreateRmaError, isPending: isCreateRmaDraftLoading } = useCreateRmaDraft();
    const rmaDraft = rmaDraftResponse?.data;
    const rmaPickupRequest = rmaPickupRequestResponse?.data;
    const resolvedRma = useMemo(() => {
        return rmaRequest || previouslyCreatedRma || rmaDraft;
    }, [previouslyCreatedRma, rmaRequest, rmaDraft]);
    const handleCancellationShippingFormShow = useCallback(() => {
        deviceSerial && dispatch(runDeviceRestockingCheck({ serial: deviceSerial }));
        // create draft RMA only if not already exists
        if (!disableCreateRma &&
            !resolvedRma &&
            !isCreateRmaDraftLoading &&
            deviceSerial &&
            cancellation) {
            const refund_status = getRefundStatus(cancellation.refund_status);
            createDraftRmaRequest({
                rma_type: 1,
                device_serial: deviceSerial,
                reason_code: cancellation.reason.code,
                reason_sub_code: cancellation.reason.sub_code,
                zendesk_ticket_number: cancellation.zendesk_ticket_number,
                ...(cancellation.notes && { comments: cancellation.notes }),
                refund_status
            });
        }
    }, [
        createDraftRmaRequest,
        resolvedRma,
        deviceSerial,
        cancellation,
        dispatch,
        runDeviceRestockingCheck,
        disableCreateRma,
        isCreateRmaDraftLoading
    ]);
    const setRmaRequestPickUpAction = useCallback((rmaId, attributes) => {
        const { return_location, return_shipping_option, is_manual_return_shipping: is_manual, address_line_1, address_line_2, city, state, zip, home_phone, return_shipping_label_url, return_tracking_number, send_box } = trimDeep(attributes);
        const phone = home_phone && formatPhoneNumber(home_phone);
        const requestParams = {
            id: rmaId,
            return_location: +return_location,
            return_shipping_option: +return_shipping_option,
            is_manual,
            send_box,
            ...(is_manual
                ? {
                    return_shipping_label_url,
                    return_tracking_number
                }
                : {
                    contact: {
                        cell_phone: phone,
                        home_phone: phone,
                        address: {
                            address_line_1: address_line_1,
                            ...(address_line_2 && { address_line_2 }),
                            city: city,
                            zip: zip,
                            state: state,
                            country: 'US'
                        }
                    }
                })
        };
        setRmaRequestPickUp(requestParams)
            .then(() => {
            setPickUpSet(true);
        })
            .catch(() => null);
    }, [setRmaRequestPickUp, resolvedRma]);
    const handleSetRmaPickUp = useCallback((formValues) => {
        if (resolvedRma?.id) {
            setRmaRequestPickUpAction(resolvedRma.id, formValues);
        }
    }, [setRmaRequestPickUpAction, resolvedRma]);
    return {
        handleCancellationShippingFormShow,
        handleSetRmaPickUp,
        isPickUpSet,
        setRmaPickupError,
        isSetRmaPickupLoading,
        createRmaDraftError,
        isCreateRmaDraftLoading,
        rmaDraft,
        rmaPickupRequest,
        isCreateRmaError
    };
};
export default useRmaRequest;
